<template>
  <div>
    <div class="row flex-center h80">
      <img style="width: 2.64rem; height: .533333rem;" src="~images/surg.png" alt />
    </div>
    <good-list :list="recommends" @detail="onDetail"></good-list>
  </div>
</template>

<script>
import GoodList from "components/content/goodList/GoodList";

export default {
  name: "HomeRecomment",
  props: {
    recommends: {
      type: Array,
      default() {
        return [];
      }
    },
    currentTabIndex: {
      type: Number
    },
    q: {
      type: String,
      default: ""
    }
  },
  methods: {
    onDetail(item) {
      if (this.currentTabIndex == -1) {
        this.$router.push({
          path: "/zydetail",
          query: { id: item.id, q: this.q }
        });
      } else {
        this.$router.push({
          path: "/detail",
          query: {
            goods_id: item.goods_id,
            index: 3,
            item: JSON.stringify(item),
            q: this.q
          }
        });
      }
    }
  },
  components: {
    GoodList
  }
};
</script>

<style scoped>
</style>
