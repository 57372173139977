<template>
    <nav-bar class="home-nav bg-white flex-center">
      <div slot="left"><img class="imgsize44 saoma" src="~images/logo.jpg" alt=""></div>
      <div slot="center"><img  class="sousuo" src="~images/sousuok.png" alt="" @click="onSearch"></div>
      <!-- <img v-if="num>0" class="imgsize48 ml16 mr25" src="~images/message.png" alt @click="onMes" />
      <img v-else class="imgsize48 ml16 mr25" src="~images/message_un.png" alt @click="onMes" /> -->
      <div slot="right" v-if="num>0"><img  class="imgsize48 saoma" src="~images/xiaoxi.png" alt="" @click="onMes"></div>
      <div slot="right" v-else><img  class="imgsize48 saoma" src="~images/xiaoxi.png" alt="" @click="onMes"></div>
    </nav-bar>
</template>

<script>
import NavBar from '@/components/common/navbar/NavBar'

export default {
  name: 'HomeSearch',
  components: {
    NavBar
  },
  props:{
num: {
      type: Number,
      default: 0
    },
  },
  methods: {
    onMes() {
      this.$router.push('/message');
    },
    onSearch() {
      this.$emit('search');
      /*
      this.$router.push({
         path: '/search',                        
         query: { q: '汽车用品' }
      });
      */
    }
  }
}
</script>

<style scoped>
.sousuo{
    width: 7.146667rem;
    height: .8rem;
    margin-top: 12px;
}
</style>
