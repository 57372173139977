<template>
<!--
    <swiper class="swiper" :autoPlay="false" >
        <slide v-for="(grid,index) in grids" :key="index">
            <div class="flex-wrap grid">
                <div class="column flex-center grid-item"  v-for="(item,i) in grid.list" :key="i">
                    <img class="img" :src="~images/yaoqingz.pngitem.image_url" alt="">
                    <span class="tv">{{item.name}}</span>
                </div>
            </div>
        </slide>
    </swiper>
    -->
    <swiper class="swiper" >
        <slide v-for="(grid,index) in grids" :key="index">
            <div class="flex-wrap grid" >
                <div class="column align-center grid-item"  v-for="(item,i) in grid.cates_list" :key="i" @click="onItem(item)">
                    <img class="img" :src="item.image_url" alt="">
                    <span class="tv">{{item.name}}</span>
                </div>
            </div>
        </slide>
        
    </swiper>
    
</template>

<script>
import {Swiper, Slide} from '@/components/common/vue-swiper'

export default {
  name: 'HomeCate',
  props: {
      grids: {
          type: Array,
          default: ()=>[],
      },
      pots: {
          type: Number,
          default:0
      }
  },
  methods: {
      onItem(item) {
          this.$emit('item',item);
      }
  },
  components: {
    Swiper,
    Slide
  }
}
</script>

<style scoped>
.swiper{
    width: 10rem;
    background-color: #fff;
}
.grid{
    margin-top: .64rem;
    margin-bottom: .32rem;
}
.grid-item{
    width: 2rem;
    margin-bottom: .506667rem;
}
.img{
    width: 1.173333rem;
    height: 1.173333rem;
}
.tv{
    font-size: .32rem;
    margin-top: .106667rem;
}

</style>
