<template>
  <div class="row flex-center" @click="onMes">
    <div class="tonggao row align-center">
      <swiper class="swiper" :showIndicator="false">
        <slide v-for="(item,index) in notifys" :key="index" class="row flex-center slide">
          <span class="f-white font24 ">{{item.title}}</span>
        </slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, Slide } from "@/components/common/vue-swiper";

export default {
  name: "HomeNotify",
  props: {
    notifys: {
      type: Array,
      default: ()=>[],
    },
    
  },
  methods:{
    onMes() {
      console.log("x");
      this.$router.push("/message");
    },
  },
  components: {
    Swiper,
    Slide
  }
};
</script>

<style scoped>
.tonggao {
  width: 9.36rem;
  height: 1.066667rem;
  background: url(~images/notifybg.png) no-repeat;
  background-size: 9.36rem 1.066667rem;
}

</style>
