<template>
  <div id="home">
    <!--<home-swiper :banners="banners" />-->
    <div class="header position_f">
      <home-search @search="onSearch" :num="num"></home-search>
      <home-tab :tabs="cates" @more="onMore" @tabClick="tabClick" />
    </div>
    <div class="content" v-if="currentTabIndex == -1">
      <home-swiper :banners="banners"></home-swiper>
      <home-cate :grids="grids" @item="onItem"></home-cate>
      <home-notify :notifys="notifys"></home-notify>
      <home-recomment :recommends="recommends" :q="q" :currentTabIndex="currentTabIndex"></home-recomment>
      <div style="height:90px;width:100%;"></div>
    </div>
    <scroll
      v-else
      class="content"
      ref="scroll"
      @pullingUp="loadMore"
      :data="recommends"
      :pull-up-load="true"
      :probe-type="3"
    >
      <div>
        <home-swiper :banners="banners"></home-swiper>
        <home-cate :grids="grids" @item="onItem"></home-cate>
        <home-notify :notifys="notifys"></home-notify>
        <home-recomment :recommends="recommends" :q="q" :currentTabIndex="currentTabIndex"></home-recomment>
        <div style="height:40px;width:100%;"></div>
      </div>
    </scroll>
  </div>
</template>

<script>
import TabControl from "@/components/content/tabControl/TabControl";
import Scroll from "@/components/common/scroll/Scroll";

import HomeSearch from "./childCmps/HomeSearch";
import HomeTab from "./childCmps/HomeTab";
import HomeSwiper from "./childCmps/HomeSwiper";
import HomeCate from "./childCmps/HomeCate";
import HomeNotify from "./childCmps/HomeNotify";
import HomeRecomment from "./childCmps/HomeRecomment";

import { rand } from "common/util";

import {
  product_categories,
  goods_search,
  ad_pictures,
  announcements,
  pingduoduo_top_goods
} from "network/home";
import {
  products
} from "network/igou2";

import { unread_count } from "network/igou";
export default {
  name: "home",
  components: {
    HomeSearch,
    HomeTab,
    Scroll,
    HomeSwiper,
    HomeCate,
    HomeNotify,
    HomeRecomment
  },
  data() {
    return {
      currentTabIndex: -1,
      cates: [],
      banners: [],
      notifys: [],
      /*
      grids: [
        {cates_list:[{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'},{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选'}]},
        {cates_list:[{id:0,image_url:'http://images.igolife.net/images/product_category/image/13/%E7%B2%BE%E5%93%81%E5%A4%96%E5%A5%97.png?imageView2/1/w/420/h/420/q/75%7Cimageslim',name:'I购优选2'}]}
      ],*/
      grids: this.getBanners(),
      recommends: [],
      page: 1,
      q: "",
      num: 0
    };
  },

  created() {
    this.get_product_categories();
    this.initData();
  },
  methods: {
    
    onMore() {
      this.$router.replace({
        path: "/category",
        query: { currentTabIndex: this.currentTabIndex }
      });
    },
    //点击分类grid回调
    onItem(item) {
      let q = item.name;
      if (this.currentTabIndex == -1) {
        let keyword = "酒业";
        if (this.cates.length > 0) {
          keyword = this.cates[rand(this.cates.length - 1)].name;
        }
        if (q == "淘宝") {
          this.goToSearch(keyword, 0);
        } else if (q == "天猫") {
          this.goToSearch(keyword, 1);
        } else if (q == "京东") {
          this.goToSearch(keyword, 2);
        } else if (q == "拼多多") {
          this.goToSearch(keyword, 3);
        } else if (q == "邀请赚") {
          this.$router.push("/share");
        }
      } else {
        this.goToSearch(q);
      }
    },
    //点击搜索组件回调
    onSearch() {
      let q = "酒业";
      if (this.cates.length > 0) {
        q = this.cates[rand(this.cates.length - 1)].name;
      }
      this.goToSearch(q);
    },
    //去搜索页面
    goToSearch(q, index) {
      this.$router.push({
        path: "/search",
        query: { q, index }
      });
    },
    getBanners() {
      return [
        {
          cates_list: [
            {
              id: 0,
              image_url: require("../../assets/img/taobao.png"),
              name: "淘宝"
            },
            {
              id: 0,
              image_url: require("../../assets/img/tianmao.png"),
              name: "天猫"
            },
            {
              id: 0,
              image_url: require("../../assets/img/jingd.png"),
              name: "京东"
            },
            {
              id: 0,
              image_url: require("../../assets/img/pingdd.png"),
              name: "拼多多"
            },
            {
              id: 0,
              image_url: require("../../assets/img/yaoqingz.png"),
              name: "邀请赚"
            }
          ]
        }
      ];
    },
    initData() {
      this.get_ad_pictures();
      this.get_announcements();
      this.get_pingduoduo_top_goods();
      /*
      unread_count().then(res => {
        this.num = res.data.count;
      });
      */
    },
    get_ad_pictures() {
      ad_pictures().then(res => {
        //console.log(res.data);
        this.banners = res.data;
      });
    },
    get_announcements() {
      announcements().then(res => {
        this.notifys = res.data;
        console.log(this.notifys);
      });
    },
    get_pingduoduo_top_goods() {
      products(this.page).then(res => {
        console.log(res)
        let list = res.data;
        if(list.length>0){
          this.recommends.push(...list);
          //this.$refs.scroll.finishPullUp();
        }
        
      });
      /*
      pingduoduo_top_goods(this.page).then(res => {
        let list = res.data.list;
        this.recommends.push(...list);
        this.$refs.scroll.finishPullUp();
      });
      */
    },
    loadMore() {
      this.page = this.page + 1;
      this.get_goods_search();
    },
    //加载首页数据
    get_product_categories() {
      product_categories().then(res => {
        this.cates = res.data;
        /*
        if (res.data.length > 0) {
          this.get_goods_search(res.data[0].name);
        }*/
      });
    },
    //加载为你推荐
    get_goods_search() {
      if (this.currentTabIndex == -1) {
        this.get_pingduoduo_top_goods();
        this.q = "";
      } else {
        this.q = this.cates[this.currentTabIndex].name;
        goods_search(this.cates[this.currentTabIndex].name, this.page).then(
          res => {
            console.log(res);
            let list = res.data.goods_list;
            this.recommends.push(...list);
            this.$refs.scroll.finishPullUp();
          }
        );
      }
    },
    tabClick(index) {
      if (this.currentTabIndex == index) {
        return;
      }
      this.banners = [];
      this.grids = [];
      this.page = 1;
      this.recommends = [];
      this.currentTabIndex = index;
      if (this.currentTabIndex == -1) {
        this.grids = this.getBanners();
        this.initData();
      } else {
        this.banners = [
          { url: "#", image_url: this.cates[index].main_image_url }
        ];
        this.getGrids(index);
        this.get_goods_search();
        //console.log(JSON.stringify(this.grids));
      }
    },
    getGrids(index) {
      let secondary_categories = this.cates[index].secondary_categories;
      let tmp = [];
      let count = 0;
      for (let i = 0; i < secondary_categories.length; i += 10) {
        let obj = {
          cates_list: secondary_categories.slice(i, i + 10)
        };
        tmp.push(obj);
        count++;
      }
      this.grids = tmp;
    }
  }
};
</script>

<style scoped>
.header {
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
}
.content {
  position: absolute;
  top: 88px;
  bottom: 49px;
  left: 0;
  right: 0;
}
</style>
