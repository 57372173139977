<template>
    <swiper >
        <slide v-for="(item, index) in banners" :key="index" >
            <img class="lunbo" :src="item.image_url " alt="">
        </slide>
    </swiper>
</template>

<script>
import {Swiper, Slide} from '@/components/common/vue-swiper'

export default {
  name: 'HomeSwiper',
  props: {
      banners: {
          type: Array,
          default() {
              return []
          }
      }
  },
  components: {
    Swiper,
    Slide
  }
}
</script>

<style scoped>
.lunbo{
    background-size: cover;
    background-position: center 0;
    height: 3.8rem;
}

</style>
