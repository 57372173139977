<style>
    .wh_slide1 {
        width: 100%;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        z-index: 10;
        min-height: 100px;
    }

    .wh_slide1 img {
        display: block;
    }
    .wh_slide {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 100%;
        flex-shrink: 0;
    }

    .wh_slide img {
        width: 100%;
    }
    .slide {
      width: 100%;
      flex-shrink: 0;
    }
    .slide img {
        width: 100%;
    }
</style>
<template>
    <div class="wh_slide" @click="clickSlide">
        <slot/>
    </div>
</template>
<script>
    export default {
        methods: {
            clickSlide() {
                this.$emit('click')
            }
        }
    }
</script>